///<reference types="@types/googlemaps" />
import { ResponsePanicService } from './../../services/response-panic.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { ConvergenceAdvisory, ConvergenceCategory, EventType } from 'src/app/interface/convergence';
import { GeocodingAddressComponent } from 'src/app/interface/employment';
import { takeUntil } from 'rxjs/operators';
import { CommonResponse } from 'src/app/interface/home';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-capture-incident',
  templateUrl: './capture-incident.component.html',
  styleUrls: ['./capture-incident.component.scss']
})
export class CaptureIncidentComponent implements OnInit {
 @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
  
  locationGeometry: string;
  isSettingLocationManually: boolean;
  manualLocAddress: string;
  manualLocLatitude: number;
  manualLocLongitude: number;
  locationLatitude: number;
  locationLongitude: number;
  locationAddressComponents: GeocodingAddressComponent[] = [];
  riskLevel: number = 3;
  uploadedPictures: any[] = [];
  userIsYetToClickUploadPicture: boolean;
  allEventTypes: EventType[] = [];
  isTypeRadioButton: number;
  allAdvisories: ConvergenceAdvisory[] = [];
  isAdvisoryRadioButton: number;
  showUplGrid: boolean = true;
  allCategoryEventTypes: EventType[] = [];
  allCategories: ConvergenceCategory[] = [];
  selectedcategory:any;
  isCategoryButton: number;
    beneficiaries: any[];
    customerDivisions: any;
  selectedCustomerDivision: any;
  selectedBeneficiary: any;
  fetchingBeneficiaries:boolean;
  address: string;
  constructor(
    private fb: FormBuilder,
    //private fileStorageService: FileStorageService,
    public responsePanicService: ResponsePanicService,
    private breadcrumbService: BreadcrumbService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService) 
    {
       // Load the Google Maps JavaScript API
   
     }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      {
        label: "Capture Incident",
      },
      {
        label: "Capture Incident",
        routerLink: ["/home/capture-incident"],
      },
    ]);
     //this.getCordinateAddress();
    // this.onScriptLoad();
    this.FetchAllEventType();
    this.FetchAllCategories();
    this.fetchAllCustomerDivisions();
  }

    ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key
    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

    //  const GCodescript = document.createElement('script');
    // GCodescript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY`;
    // GCodescript.type = "text/javascript";
    // GCodescript.onload = () => this.onScriptLoad();
    // document.head.appendChild(GCodescript);
    // document.body.removeChild(GCodescript);
  }

  // onScriptLoad() {
  //   // Define the latitude and longitude
  //   const lat = 6.4520192;
  //   const lng = 3.4308096;

  //   // Create a new geocoder instance
  //   const geocoder = new google.maps.Geocoder();

  //   // Use the geocoder to get the address from the latitude and longitude
  //   geocoder.geocode({ location: { lat, lng } }, (results, status) => {
  //     if (status === 'OK') {
  //       // Get the first result from the geocoder response
  //       const result = results[0];

  //       // Get the formatted address from the result
  //       this.address = result.formatted_address;
  //       console.log(this.address);
  //     } else {
  //       console.error(`Geocoder failed due to: ${status}`);
  //       console.log("failed");
  //     }
  //   });
  // }

  async SubmitReport() {
    this.confirmationService.confirm({
      message: "Are you sure you want to submit this incidence report?",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Submitting Incidence Report...",
        });

        const postData = {
           longitude: this.locationLongitude,
          latitude: this.locationLatitude,
          eventTypeId: this.isTypeRadioButton,
          // typeName: this.allEventTypes.find(
          //   (x) => x.id == this.isTypeRadioButton
          // ).caption,
          level: this.riskLevel,
          address: this.locationGeometry,
          clientId: this.selectedCustomerDivision.id,
          convergenceCategoryId: this.isCategoryButton,
          beneficiaryId: this.selectedBeneficiary.id,
          //address: this.locationAddressComponents,
        };
        this.responsePanicService
      .postHaloSOS(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New SOS created",
            });
            this.ClearReport();
            //this.fetch();
            //this.resourceAttachmentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          //this.submittingData = false;
        }
      );
        
      },
    });
  }

    async ClearReport() {
    this.locationGeometry = null;
    this.locationLatitude = null;
    this.locationLongitude = null;
    this.locationAddressComponents = [];
    this.isTypeRadioButton = null;
    this.isCategoryButton = null;
    this.selectedBeneficiary = null;
    this.selectedCustomerDivision = null;
    this.riskLevel = 3;
    this.showUplGrid = false;
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 secs
    this.showUplGrid = true;
  }

   async FetchAllCategories() {
    this.responsePanicService.GetAllConvergenceCategories().subscribe(
      async (data: CommonResponse) => {
        this.allCategories = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all categories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
  async FetchAllEventType() {
    this.responsePanicService.GetAllEventTypes().subscribe(
      async (data: CommonResponse) => {
        this.allEventTypes = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all event types at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }
   fetchAllCustomerDivisions() {
    this.responsePanicService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
        console.log("Divisions", res.responseData)
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
      }
    );
  }
 getBeneficiaries() {
    //this.fetchingBeneficiaries = true;
    this.responsePanicService.allClientBeneficiaryData().subscribe(
      async (r: CommonResponse) => {
        const beneficiaries = r.responseData ?? [];
        const filtered = beneficiaries.filter(
          (x) => x.clientId === this.selectedCustomerDivision.id
        );

        const benTableData = [];

        for (const ben of filtered) {
          benTableData.push(ben);
          // if (ben.isPrincipal) {
          //   ben.dependents = [];
          //   benTableData.push(ben);
          //   }
          //  else {
          //   const benData = benTableData.find(
          //     (x) => x.beneficiaryFamilyCode === x.beneficiaryFamilyCode
          //   );
          //   benData.dependents.push(ben);
          // }
        }

        this.beneficiaries = benTableData;
        console.log(this.beneficiaries);

        //this.fetchingBeneficiaries = false;
      },
      (error) => {
        this.fetchingBeneficiaries = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `An error occurred while fetching beneficiaries ${error}`,
        });
      }
    );
  }
    async FetchAllAdvisories() {
    this.responsePanicService.GetAllAdvisories().subscribe(
      async (data) => {
        this.allAdvisories = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all advisories at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

   async PickCurrentLocation() {
    this.isSettingLocationManually = false;
    await this.GetCurrentLocation();
  }

  SetLocationManually() {
    this.isSettingLocationManually = true;
  }

  async GetCurrentLocation() {
    var uri =
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY";
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        let result = JSON.parse(xmlhttp.responseText);
        localStorage.setItem("longitude", result.location.lng);
        localStorage.setItem("latitude", result.location.lat);
        //localStorage.setItem("address", result[0].formatted_address);
        console.log(result);
        document.getElementById("btnVerifyGeo").click();
      } else if (xmlhttp.status == 404) {
        alert(
          "Unable to get current location cordinates = " + xmlhttp.status + "."
        );
      }
    };
    xmlhttp.open("POST", uri, true);
    xmlhttp.send();
    xmlhttp.onerror = function () {
      alert(
        "Unable to get current location cordinates = " +
          xmlhttp.statusText +
          "."
      );
    };
  }

  GetCurrentLocationDetails() {
    let lat = localStorage.getItem("latitude");
    let lng = localStorage.getItem("longitude");
    this.locationLatitude = Number(lat);
    this.locationLongitude = Number(lng);
    //localStorage.clear();
    console.log("Latitude " + lat + " Longitude: " + lng);
    if (!lng || !lat) {
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "Unable to get your current location cordinates, Kindly check your internet connection and try again.",
      });
      return;
    }
    this.responsePanicService.GetReverseGeocodingLocation(+lat, +lng).subscribe(
      async (data) => {
        console.log("Response From GetReverseGeocodingLocation");
        console.log(data);
        console.log("-----------");
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to get cordinates location at the moment.. Reason: [" +
            error
              ? error.error.message
              : "request failed - permission" + "]",
        });
      }
    );
  }

//   getCordinateAddress(){
//     const geocoder = new google.maps.Geocoder();

// const latlng = { lat: 6.4520192, lng: 3.4308096 };

// geocoder.geocode({ location: latlng }, (results, status) => {
//   if (status === 'OK') {
//     if (results[0]) {
//       console.log(results[0].formatted_address);
//       alert("success");
//     } else {
//       alert("no res");
//       console.log('No results found');
//     }
//   } else {
//     console.log(`Geocoder failed due to: ${status}`);
//     alert("failed");
//   }
// });
//   }
  OnCategoryChange() {
    this.allCategoryEventTypes = [];
    this.isTypeRadioButton = null;
    if (this.isCategoryButton) {
      this.allCategoryEventTypes = this.allEventTypes.filter(
        (x) => x.categoryId == this.isCategoryButton
      );
    }
  }
  
}
