///<reference types="@types/googlemaps" />
import { AllResourceAttachmentsforTeamLeadPage, AllResourceAttachmentsTransferDTO, HaloSOSAssignment, HaloSOSCallReport, HaloSOSSitrep, Locations, ResponseTeam, ResponseTeamRestingStationMap } from './../../interface/response';
import { ResponsePanicService } from './../../services/response-panic.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { takeUntil } from 'rxjs/operators';
import { CommonResponse } from '../../interface/home';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { HaloSOS } from 'src/app/interface/response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { HaloReportService } from 'src/app/services/halo-report.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-response-panic',
  templateUrl: './response-panic.component.html',
  styleUrls: ['./response-panic.component.css']
})
export class ResponsePanicComponent implements OnInit {
  @ViewChild("formWrapper") public formWrapper: ElementRef;
  private unsubscriber$ = new Subject<void>();
attended;
public loading:boolean;
public loading2:boolean;
unattended;
assigned;
isShowAlert:boolean;
haloStatus:string;
showBoxOnMap:boolean = false;
 locations:Locations[];
 trackingLocations:Locations[];
haloSOS:HaloSOS[];
selectedHaloSOS:HaloSOS;
haloSOSCallReport:HaloSOSCallReport[];
selectedHaloSOSCallReport:HaloSOSCallReport;
haloSOSAssignment:HaloSOSAssignment[];
selectedHaloSOSAssignment:HaloSOSAssignment;
selectedAllResourceAttachmentsforTeamLeadPage:AllResourceAttachmentsforTeamLeadPage;
isLoadingAssigned:boolean;
isLoadingAttended:boolean;
isShowPanicBtn:boolean = false;
isNoCallReport:boolean = false;
 public selectedMultiSResponseTeams: ResponseTeam[] = [];
 public selectedMultiSResponseTeams4: ResponseTeam[] = [];
isLoadingUnattended:boolean;
isAnswered:boolean = false;
isAssignmentDialog = false;
isCallReportDialog = false;
isCallBackDialog = false;
isSitrepDialog = false;
isTeamCompositionDialog = false;
isTrackingDialog = false;
getTrackingLat:number;
getTrackingLng:number;
isShowSitreps:boolean = false;
 public haloAssignmentForm: FormGroup;
 public haloTeamCallBackForm: FormGroup;
 public haloCallreportForm: FormGroup;
  public responseTeams:ResponseTeam[];
  public responseTeams2:ResponseTeam[];
  public responseTeams3:ResponseTeam[];
  public responseTeams4:ResponseTeam[] = [];
  public responseTeams4b:ResponseTeam[];
  public responseTeams5:ResponseTeam[];
  public selectedResponseTeam:ResponseTeam;
   haloSOSSitrep:HaloSOSSitrep[];
selectedHaloSOSSitrep:HaloSOSSitrep;
  public responseTeamRestingStationMaps:ResponseTeamRestingStationMap[];
  public selectedResponseTeamRestingStationMap:ResponseTeamRestingStationMap;
    public allResourceAttachments:AllResourceAttachmentsTransferDTO[];
  public selectedResourceAttachments:AllResourceAttachmentsTransferDTO;
  droplocLongitude:number;
   Lat: number;
  Lon: number;
  speed: any;
  name: any;
  angle: any;
  droplocLatitude:number;
  sound: HTMLAudioElement;
  notAvailable = "Not Available";
  isCordinateHasValue:boolean;
   subscription: Subscription;
  everyTwoMinutes: Observable<number> = timer(0, 15000);

  carouselResponsiveOptions: any[] = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
  profileId: any;
  isLoadingTeams:boolean = false;
  distance: number;
  eta: any;
  locLatitude: any;
  locLongitude: any;
  incidentLatitude: any;
  incidentLongitude: any;
  vehicleLat:any;
  vehicleLng:any;
  isShowLocationsForAssignent:boolean;

  constructor( private breadcrumbService: BreadcrumbService,
    private responsePanicService: ResponsePanicService,
    private haloReportService: HaloReportService,
    private decimalPipe: DecimalPipe,
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    //private distanceMatrixService: DistanceMatrixService,
    public dialogService: DialogService,
    public confirmationService: ConfirmationService,) {
        this.sound = new Audio();
     }

  ngOnInit(): void {
    this.haloAssignmentForm = this.formBuilder.group({
      haloSOSId: [null, [Validators.required]],
      responseTeamId: [null, [Validators.required]],
    });
    this.haloTeamCallBackForm = this.formBuilder.group({
      haloSOSId: [null, [Validators.required]],
      responseTeamId: [null, [Validators.required]],
    });
    this.haloCallreportForm = this.formBuilder.group({
      haloSOSId: [null, [Validators.required]],
      callLog: ["", [Validators.required]],
      callAnswered: [false],
    });
    this.getProfileId();
    this.fetchStatusDashbaord();
    this.fetchUnAttendedSOS();
    //this.getEstimatedTime();

    //this.isShowAlert = true;
    //this.isShowPanicBtn = true;
    this.fetchAllResponseTeamRestingStationMaps();
     this.subscription = this.everyTwoMinutes.subscribe(() => {
      if(this.isLoadingAttended === false && this.isLoadingAssigned == false){
        this.fetchUnAttendedSOSWithSound();
        this.fetchStatusDashbaord();
      }
    });

    // this.subscription = this.everyTwoMinutes.subscribe(() => {
    //   if(this.loading2 === true){
    //     this.fetchAllNotDetachedHaloAssignmentBySOSId(this.selectedHaloSOS);
    //   }
    // });
  }

  ngAfterViewInit(): void {
    // Load google maps script after view init
    const DSLScript = document.createElement("script");
    DSLScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQuetprs2UHb_zKSXznyumyfvw95ERCDY"; // replace by your API key

    DSLScript.type = "text/javascript";
    document.body.appendChild(DSLScript);
    document.body.removeChild(DSLScript);

   
  }
  initMapTeam(): void {
    if (
      this.locLatitude &&
      this.locLongitude &&
      this.vehicleLat &&
      this.vehicleLng
    ) {
      // The location 
      const location = { lat: this.locLatitude, lng: this.locLongitude };
      const incidentlocation = {
        lat: this.vehicleLat,
        lng: this.vehicleLng,
      };
      // The map, centered at pickup Loc
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 8,
          center: location,
        }
      );

      // The marker, incident location
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });

      //vehicle loaction
      const incidentMarker = new google.maps.Marker({
        position: incidentlocation,
        map: map,
        icon:{
          url: './assets/car.png',
          scaledSize: new google.maps.Size(45, 45),
        },
      });
      //var marker1 = new google.maps.LatLng(52.5200, 13.4050);
      //var marker2 = new google.maps.LatLng(51.5074, -0.1278);
      // Calculate and display the distance between markers
      const distance = this.haversine_distance(marker, incidentMarker);
      //const distance2 = this.calculateDistance(marker, incidentMarker);
      console.log(marker)
      //const stringToconv = distance.toFixed(2);
      this.distance = distance ; //Number(stringToconv) * 1.609;

      const averageSpeedKph: number = 60.0; // Average driving speed in km/h
    
      this.eta = this.calculateTravelTime(this.distance, averageSpeedKph);
     
      console.log(this.distance + " Km ");
      console.log(this.eta + " eta ");
    }
    else{
      this.messageService.add({
        severity: "info",
        summary: "Waiting",
        detail: "Getting Cordinates: Please click on request again to get accurate location",
      });
    }
  }
   initMap(): void {
    
      
      if (this.locations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
         center: {
          lat: 9.0643305,
          lng: 7.4892974,
        },
        //center: this.locations[0],
      }

      
    );

    this.locations.forEach((x) => {
      const marker = new google.maps.Marker({
        position: x,
        map: map,
         icon:{
          url: './assets/bluemarker.png',
          scaledSize: new google.maps.Size(50, 50),
        }
        //label: x.label,
      });
    });


     const droplocation = {
        //lat: this.droplocLatitude,
        //lng: this.droplocLongitude,
        lat: this.locLatitude,
        lng: this.locLongitude,
      };
        if(this.locLatitude && this.locLongitude){
          const dropmarker = new google.maps.Marker({
        position: droplocation,
        map: map,
        // icon:{
        //   url: './assets/mapp1.png',
        //   scaledSize: new google.maps.Size(50, 50),
        // }
       
      });
      // const distance = this.haversine_distance(marker, dropmarker);
      // const stringToconv = distance.toFixed(2);
      // this.distance = Number(stringToconv) * 1.609;
      // console.log(this.distance + " Km ");
       
    }
   
       
    }
   initMap_(): void {
    //this.Lat && this.Lon
    if (this.trackingLocations.length <= 0) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
         center: {
          lat: 9.0643305,
          lng: 7.4892974,
        },
        //center: this.locations[0],
      }
    );

    const incidentLocation = {
        lat: this.getTrackingLat,
        lng: this.getTrackingLng,
      };
        if(this.getTrackingLat && this.getTrackingLng){
          const marker = new google.maps.Marker({
        position: incidentLocation,
        map: map,
        // icon:{
        //   url: './assets/mapp1.png',
        //   scaledSize: new google.maps.Size(50, 50),
        // }
       
      });
     
    }

    this.trackingLocations.forEach((x) => {
      // The marker, positioned at Loc
      const marker = new google.maps.Marker({
        position: x,
        map: map,
         icon:{
          url: './assets/car.png',
          scaledSize: new google.maps.Size(45, 45),
        },
        label: x.lat.toString(),
      });
    });
   
  }
   initMapIncidentLocation(): void {
    //this.Lat && this.Lon
    //if (this.locLatitude  || this.locLongitude) return;

    // The map, centered at Loc
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 6,
         center: {
          lat: 9.0643305,
          lng: 7.4892974,
        },
        //center: this.locations[0],
      }
    );

    const incidentLocation = {
        lat: this.locLatitude,
        lng: this.locLongitude,
      };
        if(this.locLatitude && this.locLongitude){
          const marker = new google.maps.Marker({
        position: incidentLocation,
        map: map,
        // icon:{
        //   url: './assets/mapp1.png',
        //   scaledSize: new google.maps.Size(50, 50),
        // }
       
      });
     
    }
  }


  haversine_distance(mk1, mk2) {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    console.log(mk1.position.lat(),mk2.position.lng())
    const dLat = this.toRadians(mk2.position.lat() - mk1.position.lat());
    const dLon = this.toRadians(mk2.position.lng() - mk1.position.lng());
    console.log(dLat, dLon, "lat lon")
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(mk1.position.lat())) * Math.cos(this.toRadians(mk2.position.lat())) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
      console.log(a, "a")
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    console.log(c, "c")
    var distance1 = earthRadius * c;
    var distance = distance1 *= 1.3;//for driving mode
    console.log(distance, "same dist")
    return distance;

   // =========
    //var R = 3958.8; // Radius of the Earth in miles
    // var R = 6371; //use 6371.0710 to get the Radius in kilometers
    // var rlat1 = mk1.position.lat() * (Math.PI / 180); // Convert degrees to radians
    // var rlat2 = mk2.position.lat() * (Math.PI / 180); // Convert degrees to radians
    // var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    // var difflon = (mk2.position.lng() - mk1.position.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    // var d =
    //   2 *
    //   R *
    //   Math.asin(
    //     Math.sqrt(
    //       Math.sin(difflat / 2) * Math.sin(difflat / 2) +
    //         Math.cos(rlat1) *
    //           Math.cos(rlat2) *
    //           Math.sin(difflon / 2) *
    //           Math.sin(difflon / 2)
    //     )
    //   );
    //===========
   
  }

   toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  calculateTravelTime(distanceKms: number, averageSpeedKph: number): string {
    const travelTimeHours: number = distanceKms / averageSpeedKph;
  
    // Optionally, convert travel time to hours and minutes
    const travelTimeMinutes: number = Math.floor(travelTimeHours * 60);
    const hours: number = Math.floor(travelTimeMinutes / 60);
    const minutes: number = travelTimeMinutes % 60;
  
    return `${hours} hours ${minutes} minutes`;
  }
//   getEstimatedTime(){
//         const incidentLocation2 = {
//         lat: 40.7128,
//         lng: -74.0060
//       };
//       const incidentLocation = {
//         lat: 37.7749,
//         lng: -122.4194
//       };
//        const directionsService = new google.maps.DirectionsService();
// const origin = incidentLocation; //new google.maps.LatLng(37.7749, -122.4194); // San Francisco
// const destination = incidentLocation2; //new google.maps.LatLng(40.7128, -74.0060); // New York City
// const request = {
//   origin: origin,
//   destination: destination,
//   travelMode: google.maps.TravelMode.DRIVING
// };
// directionsService.route(request, (response, status) => {
//   if (status === google.maps.DirectionsStatus.OK) {
//     const arrivalTime = response.routes[0].legs[0].arrival_time.text;
//     console.log(`Estimated arrival time: ${arrivalTime}`);
//   }
//   else{
//     console.log("error occured");
//   }
// });
//   }
fetchMap(){
  //this.loading = true;
  this.isCordinateHasValue = false;
  this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "fetching locations...",
    });
  this.initMap();
  //this.loading = false;
    this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Locations fetch succesful",
            });
}

 fetchAllResponseTeamRestingStationMaps() {
    this.loading=true;
    this.responsePanicService
      .allResponseTeamRestionStationMaps()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeamRestingStationMaps = res;
         this.locations = [];
        res.forEach((response) => {
          this.locations.push({
            lat: response.restingStation.latitude,
            lng: response.restingStation.longitude,
          });
        });
        console.log("Loc", this.locations);
         this.initMap();
          this.loading=false;
          console.log("Ress",res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.loading=false;
            //this.submittingData = false;
          }
      });
  }
   checkForNewData() {
    // Get the list data from your data source, such as a service
    const newData = this.responsePanicService.allUnattendedSOS();

    // Compare the new data with the existing data
    if (JSON.stringify(newData) !== JSON.stringify(this.haloSOS)) {
      console.log('New data added!');
      //this.haloSOS = newData;
    }
  }

  viewSitreps(haloSOS: HaloSOS){

    this.loading = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "fetching SOS Sitreps",
    });
    this.fetchAllSitrepsBySOSId(haloSOS.id);
    this.loading = false;
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "fetched SOS Sitreps",
    });
  }

  viewSitrepsDialog(team: ResponseTeam){

    this.loading = true;
    this.isSitrepDialog = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "fetching SOS Sitreps",
    });
    this.fetchAllSitrepsBySOSId(team.haloSOSId);
    
    this.loading = false;
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "fetched SOS Sitreps",
    });
  }

   fetchStatusDashbaord(): void {
    this.loading = true;
    this.responsePanicService
      .allHaloSOSDashboadItem()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.attended = res.attended;
            this.unattended = res.unattended;
            this.assigned = res.assigned;
           console.log(res);
           this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
     fetchAttendedSOS(): void {
    this.loading = true;
    this.loading2 = false;
    this.isLoadingAssigned = false;
    this.isLoadingAttended = true;
    this.isLoadingUnattended = false;
    this.isShowLocationsForAssignent = false;
    //this.showBoxOnMap = false;
     this.responseTeams2 = [];
     this.responseTeams4 = [];
    this.haloStatus = "Attended SOS"
    this.responsePanicService
      .allAttendedSOS()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOS=[];
            this.haloSOS = res;
            this.loading = false;
           console.log(res);
           this.fetchStatusDashbaord()
          }
        },
        (error) => {
          this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
     fetchUnAttendedSOS(): void {
    this.loading = true;
    this.loading2 = false;
    this.isLoadingAssigned = false;
    this.isLoadingAttended = false;
    this.isLoadingUnattended = true;
     this.showBoxOnMap = false;
    this.haloStatus = "UnAttended SOS"
    this.responsePanicService
      .allUnattendedSOS()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOS=[];
            this.haloSOS = res;
            var initCount = this.haloSOS.length;
            localStorage.setItem('countSOS', String(initCount));
            this.loading = false;
            console.log(this.haloSOS)
            this.fetchStatusDashbaord()
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
    fetchUnAttendedSOSWithSound(): void {
    this.loading = true;
    this.isLoadingAssigned = false;
    this.isLoadingAttended = false;
    this.isLoadingUnattended = true;
     this.showBoxOnMap = false;
    this.haloStatus = "UnAttended SOS"
    this.responsePanicService
      .allUnattendedSOS()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOS=[];
            this.haloSOS = res;
            var newCount = this.haloSOS.length;
            var oldCount = Number(localStorage.getItem("countSOS"));
            //localStorage.setItem('countSOS', String(initCount));
            if(newCount > oldCount){
              this.isShowAlert = true;
              this.playSound();
              this.selectedHaloSOS = this.haloSOS[0];
              //console.log("one item", this.selectedHaloSOS)
              this.fetchStatusDashbaord();
              //this.showBoxOnMap = true;
            }
            this.loading = false;
            localStorage.setItem('countSOS', String(newCount));
            console.log("newcount, oldcount", newCount, oldCount)
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
     fetchAssignedSOS(): void {
    this.loading = true;
    this.loading2 = false;
    this.isLoadingAssigned = true;
    this.isLoadingAttended = false;
    this.isLoadingUnattended = false;
     this.showBoxOnMap = false;
     this.isShowLocationsForAssignent = false;
     this.responseTeams2 = [];
     this.responseTeams4 = [];
    this.haloStatus = "Assigned SOS"
    this.responsePanicService
      .allAssignedSOS()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOS = res;
            this.loading = false;
            this.fetchStatusDashbaord()
          }
        },
        (error) => {
          this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }  
   fetchHaloAssignmentBySOSId(id): void {
    this.loading = true;
    this.responsePanicService
      .getHaloSOSAssignmentByHaloSOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOSAssignment = res;
            console.log(this.haloSOSAssignment);
            this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
   fetchAllHaloAssignmentBySOSId(haloSOS: HaloSOS): void {
    this.loading = true;
    this.getTrackingLat = haloSOS.latitude;
    this.getTrackingLng  = haloSOS.longitude;
    console.log("get tracking lat and lon", this.getTrackingLat, this.getTrackingLng);
    this.responsePanicService
      .getHaloSOSAssignmentByHaloSOSId(haloSOS.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.responseTeams = res;
          const _assignments = this.responseTeams;
           this.responseTeams2 = [];
            this.responseTeams2 = _assignments.filter(
              (ass) =>
                ass.isAssigned === true
            );
            console.log(res);
            this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }

   fetchAllNotDetachedHaloAssignmentBySOSId(haloSOS: HaloSOS): void {
    this.loading = true;
    this.loading2 = true;
    this.selectedHaloSOS = haloSOS;
    this.getTrackingLat = haloSOS.latitude;
    this.getTrackingLng  = haloSOS.longitude;
    this.locLatitude = haloSOS.latitude;
    this.locLongitude  = haloSOS.longitude;
    //console.log("get tracking lat and lon", this.getTrackingLat, this.getTrackingLng);
    this.responsePanicService
      .getAllResponseTeamsByHaloSOSId(haloSOS.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.responseTeams = res;
          const _assignments = this.responseTeams;
           this.responseTeams4b = [];
            this.responseTeams4b = _assignments.filter(
              (ass) =>
                ass.isAssigned === true
            );
            const filteredTeam = this.responseTeams4b;
            this.responseTeams4 = [];
            filteredTeam.forEach((team) =>{
              this.responsePanicService.getResponseTeamResourceAttachment(team.id)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe((r: CommonResponse) => {
                if (r.responseCode == "00") {
                  var res = r.responseData;
                  this.allResourceAttachments = res;
                   this.trackingLocations = [];
                   const imeis = [];
                   res.forEach((response) => {
                    if(response.imeiReferenceNumber != ""){
                      imeis.push(response.imeiReferenceNumber);
                    }
                    
                   }),
                   console.log("imeis", imeis);
                    imeis.forEach((response) => {
                      let trackId = response;
                       this.responsePanicService
              .vehicleTracking(response)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe(
                (r: CommonResponse) => {
                  if (r.responseCode == "00") {
                    var res = r.responseData;
                      //this.isCordinateHasValue = true;
                    console.log(res);
                    if (res === null || res.length < 1) {
                      this.isTrackingDialog = false;
                      this.messageService.add({
                        severity: "error",
                        summary: "Failed",
                        detail:
                          " Error: Tracking Imei not available for selected vehicle",
                      });
                    } else {
                      this.isCordinateHasValue = true;
                      console.log("res", res);
                      this.vehicleLat = parseFloat(res[trackId].lat);
                      this.vehicleLng = parseFloat(res[trackId].lng);
                      console.log("VehLatLan", this.vehicleLat, this.vehicleLng)
                     
                    }
                    
                  }
                },
                (error) => {
                  this.isTrackingDialog = false;
                  this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail:
                      "Tracking Error: Incorrect Imei provided  or connection error",
                  });
                }
              );
                 
                });
                  this.loading=false;
                 ///success
                }
                 else {
                    this.messageService.add({
                      severity: "error",
                      summary: "Failed",
                      detail: " Error: " + r.responseMsg,
                    });
                    //this.submittingData = false;
                  }
              });              
              //this.incidentLatitude = this.vehicleLat;//vehicle latitude
              //this.incidentLongitude = this.vehicleLng; //vehicle long
              if(this.vehicleLat !== 0.0 || this.vehicleLng !== 0.0){
                this.initMapTeam();
                const distanceinKm = this.decimalPipe.transform(this.distance, '1.2-2')
                team.distanceInKm = distanceinKm;
                team.ETA = this.eta;
              }
              
              this.responseTeams4.push(team);
            })
            console.log(this.responseTeams4);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }

  
  fetchHaloSOSById(id): void {
    this.loading = true;
    this.responsePanicService
      .getHaloSOSAssignmentByHaloSOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
            this.haloSOS = res;
            this.loading = false;
          }
        },
        (error) => {
          //this.loading = false;
          //this.connectionError();
        }
      );
    //this.submittingData = false;
  }
  fetchAllResponseTeams() {
    this.responsePanicService
      .allResponseTeams()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          //this.responseTeams2 = res;
          const _assignments = res;
           this.responseTeams = [];
            this.responseTeams = _assignments.filter(
              (ass) =>
                ass.isAssigned === false
            );
            
          console.log(this.responseTeams);
          console.log("assign", _assignments);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }

  fetchAllSitrepsBySOSId(id) {
    this.loading = true;
    this.haloReportService
      .getAllHaloSOSSitrepBySOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.haloSOSSitrep = res;
          this.loading = false;
          console.log("sitrep rep", this.haloSOSSitrep);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            this.loading = false;
            //this.submittingData = false;
          }
      });
      this.loading = false;
  }

   fetchAllResponseTeamsBySOSId(id) {
    this.responsePanicService
      .getAllResponseTeamsByHaloSOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          //this.responseTeams = res;
          const _assignments = res;
           this.responseTeams3 = [];
            this.responseTeams3 = _assignments.filter((ass) => ass.isAssigned === true);
            
          console.log(this.responseTeams3);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }
   fetchAllResponseTeamsBySOSId_(id) {
    this.responsePanicService
      .getAllResponseTeamsByHaloSOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          //this.responseTeams = res;
          const _assignments = res;
           this.responseTeams4 = [];
            this.responseTeams4 = _assignments.filter((ass) => ass.isAssigned === true);
            
          console.log(this.responseTeams4);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }
   closeHaloSOS(haloSOS: HaloSOS): void {
    this.confirmationService.confirm({
      message: "Are you sure you want to close this SOS",
      accept: () => {
        this._closeHaloSOS(haloSOS);
      },
    });
  }
  _closeHaloSOS(haloSOS) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Closing SOS",
    });
    this.responsePanicService.ReleaseTeamBySOSId(haloSOS.id).subscribe(
      async (result: any) => {
        await this.messageService.add({
          severity: "success",
          summary: "Deleted",
          detail: "SOS closed and all teams released",
        });
        await this.fetchAttendedSOS();
        await this.fetchStatusDashbaord();
      },
      (error) => {
        this.connectionError();
      }
    );
  }
  SubmitHaloAssignment() {
    //alert("not yet impl");
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Assigning Teams",
    });
    const formData = this.haloAssignmentForm.value;
        const postData = {
           haloSOSId: formData.haloSOSId,
          responseTeamId: this.selectedMultiSResponseTeams.map(
        (x) => x.id
      ),
         
          //address: this.locationAddressComponents,
        };
        this.responsePanicService
      .postHaloSOSAssignment(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "New Assignment created",
            });
            this.fetchUnAttendedSOS();
            this.fetchStatusDashbaord();
            this.fetchAllResponseTeams();
            this.haloAssignmentForm.reset();
            this.isAssignmentDialog = false;
            //this.resourceAttachmentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          //this.submittingData = false;
        }
      );
  }
 SubmitForTeamCallBack() {
    //alert("not yet impl");
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Calling Back Team(s)",
    });
    const formData = this.haloTeamCallBackForm.value;
        const postData = {
           haloSOSId: formData.haloSOSId,
          responseTeamId: this.selectedMultiSResponseTeams4.map(
        (x) => x.id
      ),
         
          //address: this.locationAddressComponents,
        };
        this.responsePanicService
      .releaseTeamsAssignedbyTeamId(postData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Team(s) called back successfully",
            });
            this.fetchUnAttendedSOS();
            this.fetchStatusDashbaord();
            //this.fetchAllResponseTeams();
            this.haloTeamCallBackForm.reset();
            this.isCallBackDialog = false;
            //this.resourceAttachmentForm.reset();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: "Connection Error " + error.error.message,
          });
          //this.submittingData = false;
        }
      );
  }

  CallReportSuccessSubmit(){
    const formData = this.haloCallreportForm.value;
    this.messageService.add({
      summary: "Success",
      severity: "success",
      detail: "New Call report added",
    });
    //this.isCallReportDialog = true;
    //this.haloCallreportForm.reset();
   this.haloCallreportForm.patchValue({
    haloSOSId: formData.haloSOSId,
    callLog: "",
    callAnswered: false,
   });
    this.fetchUnAttendedSOS();
    this.fetchStatusDashbaord();
    this.fetchAllCallReportBySOSId(formData.haloSOSId);
  }
   async SubmitCallReport() {
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Adding call report",
    });
       const formData = this.haloCallreportForm.value;
        const postData = {
           haloSOSId: formData.haloSOSId,
            callLog: formData.callLog,
            callAnswered: formData.callAnswered,
        };
        if(formData.callAnswered === true){
          this.confirmationService.confirm({
            message: "You have indicated that this SOS request is a false alarm, please note that proceeding with this request will mean that the SOS request will be retired and all previous teams allocated if any, would be released. Would you like to proceed?",
            accept: () => {
              this.haloReportService
              .postHaloSOSCallReport(postData)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe(
                (r: CommonResponse) => {
                  if (r.responseCode == "00") {
                  this.CallReportSuccessSubmit();
                   this.isCallReportDialog = false;
                  } else {
                    this.messageService.add({
                      severity: "error",
                      summary: "Failed",
                      detail: " Error: " + r.responseMsg,
                    });
                    //this.submittingData = false;
                  }
                },
                (error) => {
                  this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: "Connection Error " + error.error.message,
                  });
                  //this.submittingData = false;
                }
              );
            },
          });
        }
       else{
        this.haloReportService
        .postHaloSOSCallReport(postData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe(
          (r: CommonResponse) => {
            if (r.responseCode == "00") {
              this.messageService.add({
                summary: "Success",
                severity: "success",
                detail: "New Call report added",
              });
              //this.isCallReportDialog = false;
              //this.haloCallreportForm.reset();
              // this.haloCallreportForm.patchValue({
              //   haloSOSId: formData.haloSOSId,
              //   callLog: "",
              //   callAnswered: false,
              //  });
              // this.fetchUnAttendedSOS();
              // this.fetchStatusDashbaord();
              this.CallReportSuccessSubmit();
             
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail: " Error: " + r.responseMsg,
              });
              //this.submittingData = false;
            }
          },
          (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: "Connection Error " + error.error.message,
            });
            //this.submittingData = false;
          }
        );
       }
      
  }
   fetchAllCallReportBySOSId(id) {
    this.haloReportService
      .getAllHaloSOSCallReportBySOSId(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.haloSOSCallReport = res;
          console.log("call rep", this.haloSOSCallReport);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }
 fetchResourceAttachmentByTeamId(resTeam: ResponseTeam) {
    this.loading=true;
    this.isTeamCompositionDialog = true;
    this.responsePanicService
      .getResponseTeamResourceAttachment(resTeam.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allResourceAttachments = res;
          //this.showattachmenttab =true;
          this.loading=false;
          console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }
  
TrackTeamByByTeamId(resTeam: ResponseTeam) {
    this.loading=true;
    //this.isTeamCompositionDialog = true;
    this.responsePanicService
      .getResponseTeamResourceAttachment(resTeam.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.allResourceAttachments = res;
           this.trackingLocations = [];
           const imeis = [];
           res.forEach((response) => {
            if(response.imeiReferenceNumber != ""){
              imeis.push(response.imeiReferenceNumber);
            }
            
           }),
           console.log("imeis", imeis);
            imeis.forEach((response) => {
              let trackId = response;
               this.responsePanicService
      .vehicleTracking(response)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
              //this.isCordinateHasValue = true;
            console.log(res);
            if (res === null || res.length < 1) {
              this.isTrackingDialog = false;
              this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail:
                  " Error: Tracking Imei not available for selected vehicle",
              });
            } else {
              this.isCordinateHasValue = true;
              console.log("res", res);
              this.vehicleLat = parseFloat(res.lat);
              this.vehicleLng = parseFloat(res.lng);
              //this.isTeamCompositionDialog = false;
               this.trackingLocations.push({
                  lat: parseFloat(res.lat),
                  lng: parseFloat(res.lng),
              });
              // this.Lat = parseFloat(res[trackId].lat);
              // this.Lon = parseFloat(res[trackId].lng);
              // this.speed = res[trackId].speed;
              // this.name = res[trackId].name;
              // this.angle = res[trackId].angle;
              this.initMap_();
               
              console.log("c# Res Lat and Lon: ", this.trackingLocations);
            }
            
          }
        },
        (error) => {
          this.isTrackingDialog = false;
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Tracking Error: Incorrect Imei provided  or connection error",
          });
        }
      );
         
        });
          //this.showattachmenttab =true;
          
          this.loading=false;
          this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Tracking data succesful",
            });
          //console.log(res);
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
  }

  ViewHaloSOSDetailsAndCordinates(haloSOS: HaloSOS){
    this.isShowLocationsForAssignent = true;
    this.showBoxOnMap= true;
     this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Getting SOS location and teams",
    });
    this.selectedHaloSOS = haloSOS;
    this.locLatitude= haloSOS.latitude;
    this.locLongitude = haloSOS.longitude;
    // this.droplocLatitude= haloSOS.latitude;
    // this.droplocLongitude = haloSOS.longitude;
    //console.log("lacc", this.droplocLatitude + " "+ this.droplocLongitude);
   
    this.responsePanicService
    .allResponseTeamsForAssignment()
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe(
      (r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.responseTeams = res;
        const _assignments = this.responseTeams;
         this.responseTeams4b = [];
          this.responseTeams4b = _assignments.filter(
            (ass) =>
              ass.isAssigned === false
          );
          const filteredTeam = this.responseTeams4b;
          this.responseTeams5 = [];
          filteredTeam.forEach((team) =>{
            this.responsePanicService.getResponseTeamResourceAttachment(team.id)
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe((r: CommonResponse) => {
              if (r.responseCode == "00") {
                var res = r.responseData;
                this.allResourceAttachments = res;
                 this.trackingLocations = [];
                 const imeis = [];
                 res.forEach((response) => {
                  if(response.imeiReferenceNumber != ""){
                    imeis.push(response.imeiReferenceNumber);
                  }
                  
                 }),
                 console.log("imeis", imeis);
                  imeis.forEach((response) => {
                    let trackId = response;
                     this.responsePanicService
            .vehicleTracking(response)
            .pipe(takeUntil(this.unsubscriber$))
            .subscribe(
              (r: CommonResponse) => {
                if (r.responseCode == "00") {
                  var res = r.responseData;
                    //this.isCordinateHasValue = true;
                  console.log(res);
                  if (res === null || res.length < 1 ) {
                    this.initMap();
                    this.isTrackingDialog = false;
                    this.messageService.add({
                      severity: "error",
                      summary: "Failed",
                      detail:
                        " Error: Tracking Imei not available for selected vehicle",
                    });
                  } else {
                    this.isCordinateHasValue = true;
                    console.log("res", res);
                    this.vehicleLat = parseFloat(res.lat);
                    this.vehicleLng = parseFloat(res.lng);
                    console.log("VehLatLan", this.vehicleLat, this.vehicleLng)
                   
                  }
                  
                }
              },
              (error) => {
                this.isTrackingDialog = false;
                this.initMap();
                this.messageService.add({
                  severity: "error",
                  summary: "Failed",
                  detail:
                    "Tracking Error: Incorrect Imei provided  or connection error",
                });
              }
            );
               
              });
                this.loading=false;
               ///success
              }
               else {
                  this.messageService.add({
                    severity: "error",
                    summary: "Failed",
                    detail: " Errorr: " + r.responseMsg,
                  });
                  //this.submittingData = false;
                }
            });              
            //this.incidentLatitude = this.vehicleLat;//vehicle latitude
            //this.incidentLongitude = this.vehicleLng; //vehicle long
            if(this.vehicleLat !== 0.0 || this.vehicleLng !== 0.0){
              this.initMapTeam();
              const distanceinKm = this.decimalPipe.transform(this.distance, '1.2-2')
              team.distanceInKm = distanceinKm;
              team.ETA = this.eta;
            }
            
            this.responseTeams5.push(team);
          })
          console.log(this.responseTeams5);
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        //this.connectionError();
      }
    );
    this.initMap();
    this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "SOS location retrieved",
            });
    //alert("not yet impl");
  }
 getProfileId() {
    let result = JSON.parse(sessionStorage.getItem("userProfile"));
    sessionStorage.setItem("id", result.id);
    this.profileId = sessionStorage.getItem("id");
  }
   showAssignmentDialog(haloSOS: HaloSOS){
    
    this.haloAssignmentForm.patchValue({
      haloSOSId: haloSOS.id,
    })
    this.fetchAllResponseTeams();
     this.haloReportService
      .getAllHaloSOSCallReportBySOSId(haloSOS.id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: CommonResponse) => {
        if (r.responseCode == "00") {
          var res = r.responseData;
          this.haloSOSCallReport = res;
          if(this.haloSOSCallReport.length < 1){
            this.isNoCallReport = true;
            return;
          }
          else{
            this.isAssignmentDialog = true;
          }
        }
         else {
            this.messageService.add({
              severity: "error",
              summary: "Failed",
              detail: " Error: " + r.responseMsg,
            });
            //this.submittingData = false;
          }
      });
    //alert("not yet impl");
  }
  showTeamCallBackDialog(haloSOS: HaloSOS){
    this.isCallBackDialog = true;
    this.haloTeamCallBackForm.patchValue({
      haloSOSId: haloSOS.id,
    })
    this.fetchAllResponseTeamsBySOSId_(haloSOS.id);
    //alert("not yet impl");
  }
   showCallReportDialog(haloSOS: HaloSOS){
    this.selectedHaloSOS = haloSOS;
    this.isCallReportDialog = true;
     this.haloCallreportForm.patchValue({
      haloSOSId: haloSOS.id,
    })
    this.fetchAllCallReportBySOSId(haloSOS.id);
    //alert("not yet impl");
  }
   playSound() {
    this.sound.src = './assets/alarm1.mp3';
    this.sound.load();
    this.sound.play();
   
  }
  
  TrackVehicle(attachment: AllResourceAttachmentsTransferDTO) {
    //this.isTrackingDialog = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "fetching tracking data...",
    });
    let trackId =
      attachment.imeiReferenceNumber;
      console.log("attach:", attachment)
    let resId = attachment.resourceId;
    console.log("trackid:",trackId);
    //this.vehicleServiceAssignmentDetail = serviceAssignment;
    //console.log("AssignmentDetail: ", this.vehicleServiceAssignmentDetail);

    if (trackId === null || trackId === "") {
      //this.isTrackingDialog = false;
      this.isCordinateHasValue = false;
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: " Error: No Imei provided for selected vehicle",
      });
    }
    this.responsePanicService
      .vehicleTracking(trackId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (r: CommonResponse) => {
          if (r.responseCode == "00") {
            var res = r.responseData;
              //this.isCordinateHasValue = true;
            console.log(res);
            if (res === null || res.length < 1) {
              this.isTrackingDialog = false;
              this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail:
                  " Error: Tracking Imei not available for selected vehicle",
              });
            } else {
              this.isCordinateHasValue = true;
              this.isTeamCompositionDialog = false;
              //this.isTrackingDialog = true;
              this.formWrapper.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
              });
              this.Lat = parseFloat(res.lat);
              this.Lon = parseFloat(res.lng);
              this.speed = res.speed;
              this.name = res.name;
              this.angle = res.angle;
              this.initMap_();
               this.messageService.add({
              summary: "Success",
              severity: "success",
              detail: "Tracking data succesful",
            });
              console.log("c# Res Lat and Lon: ", this.angle + "" + this.name);
            }
            
          }
        },
        (error) => {
          this.isTrackingDialog = false;
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail:
              "Tracking Error: Incorrect Imei provided  or connection error",
          });
        }
      );
    console.log("IMEI Id", trackId);
    
  }
  pauseSound(){
    console.log("clicked");
     this.sound.pause();
     
  }
  showBox(){
    alert("not yet impl");
  }
  getSanitizedUrl(url: string): any {
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
 private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
