import { Injectable } from '@angular/core';
import { CommonResponse } from '../interface/home';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConvergenceAdvisory, ConvergenceCategory, EventType } from '../interface/convergence';
import { GetReverseGeocodingLocationRespVM } from '../interface/employment';
import { environment } from 'src/environments/environment';
import { ApiLoadingService } from './api-loading.service';
import { FireBaseAuthService } from './fire-base-auth.service';
@Injectable({
  providedIn: 'root'
})
export class ResponsePanicService {
 baseUrl2 = environment.baseUrl;
 baseUrl = environment.onlineBackendBaseUrl_;
  //baseUrl2 = "https://localhost:44344/api/v1/";
  //baseUrl = "https://localhost:44353/api/";;
  baseUrl_ = environment.gmaBaseUrl  ;
 //baseUrl_ = environment.gmaBaseUrl + "Convergence/";
  //baseUrl = environment.gmaBaseUrl + "Employment/";
  constructor(private httpClient: HttpClient, 
    private fireBaseAuthService: FireBaseAuthService,) { }
   
      allResponseTeams(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllResponseTeams"
    );
  }

  allResponseTeamsForAssignment(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
  
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllResponseTeamsForAssignment"
    );
  }
  //HaloSOS
  allHaloSOS(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllHaloSOS"
    );
  }
    allAttendedSOS(): Observable<CommonResponse> {
    //   const token = this.fireBaseAuthService.token;
    // const refreshToken = this.fireBaseAuthService.refreshToken;
    // const headers = {
    //   Accept: "application/json",
    //   "Access-Control-Allow-Origin": "*",
    //   Authorization: `Bearer ${token}`,
    //   "xr-token": `${refreshToken}`,
    //   "Cache-Control": "no-cache",
    //   Pragma: "no-cache",
    //   Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    // };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllAttendedSOS"
    );
  }
    allUnattendedSOS(): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllUnattendedSOS"
    );
  }
    allAssignedSOS(): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllAssignedSOS"
    );
  }
    getHaloSOS(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetHaloSOSById/" + id
    );
  }
   
  allHaloSOSDashboadItem(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetHaloSOSDashboardItems"
    );
  }
   postHaloSOS(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
   
    return this.httpClient.post(
      this.baseUrl + "HaloSOS/AddNewHaloSOS",
      data
    );
  }
  deleteHaloSOS(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/DeleteHaloSOS/" + id
    );
  }
  setHaloSOSAsDealtWithBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/SetHaloSOSAsDealtWithBySOSId/" + id
    );
  }
   setHaloSOSAsClosedBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/SetHaloSOSAsClosedBySOSId/" + id
    );
  }
  

  //Assignment
  allHaloSOSAssignment(): Observable<CommonResponse> {
    
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllHaloSOSAssignments"
    );
  }
    getHaloSOSAsssignment(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetHaloSOSAssignmentById/" + id
    );
  }
    getHaloSOSAssignmentByHaloSOSId(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetHaloSOSAssignmentByHaloSOSId/" + id
    );
  }

    getAllNotDetachedHaloSOSAssignmentByHaloSOSId(id: any): Observable<CommonResponse> {
      const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllNotDetachedSOSAssignmentByHaloSOSId/" + id
    );
  }

  getAllResponseTeamsByHaloSOSId(id: any): Observable<CommonResponse> {
      
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllResponseTeamsByHaloSOSId/" + id
    );
  }

   getHaloSOSAssignmentToLeadProfileId(id: any): Observable<CommonResponse> {
     
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetHaloSOSAssignmentToTeamLeadByProfileId/" + id
    );
  }
   
   postHaloSOSAssignment(data: any) {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.post(
      this.baseUrl + "HaloSOS/AddNewHaloSOSAssignment",
      data
    );
  }
   releaseTeamsAssignedbyTeamId(data: any) {
   
    return this.httpClient.post(
      this.baseUrl + "HaloSOS/ReleaseTeamsByTeamId",
      data
    );
  }
  deleteHaloSOSAssignment(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/DeleteHaloSOSAssignment/" + id
    );
  }
   setHaloSOSAssignmentAsAccepted(id: any, profileId) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/SetHaloSOSAssignmentAsAcceptedByAssignmentId/" + id + "/" + profileId
    );
  }
  SetHaloSOSAssignmentAsRejected(data) {
    return this.httpClient.post(
      this.baseUrl + "HaloSOS/SetHaloSOSAssignmentAsRejected", data
    );
  }
   setHaloSOSAssignmentAsStarted(id: any, profileId) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/SetHaloSOSAssignmentAsStartedByAssignmentId/" + id + "/" + profileId
    );
  }
   setHaloSOSAssignmentAsStopped(id: any, profileId) {
    return this.httpClient.get(
      this.baseUrl + "HaloSOS/SetHaloSOSAssignmentAsStoppedByAssignmentId/" + id + "/" + profileId
    );
  }
  setHaloSOSAssignmentAsDetached(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/SetHaloSOSAssignmentAsDetachedByAssignmentId/" + id
    );
  }
   updateHaloSOSAssignment(id: any, data: any) {
    return this.httpClient.put(
      this.baseUrl + "HaloSOS/UpdateHaloSOSAssignment/" + id,
      data
    );
  }
    ReleaseTeamBySOSId(id: any) {
    return this.httpClient.delete(
      this.baseUrl + "HaloSOS/ReleaseTeamByHaloSOSId/" + id
    );
  }

  GetAllEventTypes(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "HaloSOS/GetAllEventTypes");
  }
   GetAllAdvisories(): Observable<ConvergenceAdvisory[]> {
    return this.httpClient.get<ConvergenceAdvisory[]>(
      this.baseUrl_ + "GetAllAdvisories"
    );
  }

   GetReverseGeocodingLocation(
    latitude: number,
    longititude: number
  ): Observable<GetReverseGeocodingLocationRespVM> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<GetReverseGeocodingLocationRespVM>(
      this.baseUrl_ +
        "Employment/GetReverseGeocodingLocation/" +
        latitude +
        "/" +
        longititude, {headers}
    );
  }

   GetAllConvergenceCategories(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllConvergenceCategories"
    );
  }
   allCustomerDivision(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "HaloSOS/GetAllCustomerDivisions", {headers});
  }

   allClientBeneficiaryData(): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(this.baseUrl + "HaloSOS/ClientBeneficiary");
  }
   getResponseTeamResourceAttachment(id: any): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllResouceAttachmentsByTeamId/" + id
    );
  }
  allResponseTeamRestionStationMaps(): Observable<CommonResponse> {
    const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetAllResponseTeamRestingStationMaps"
    );
  }

  vehicleTracking(id): Observable<CommonResponse> {
     const token = this.fireBaseAuthService.token;
    const refreshToken = this.fireBaseAuthService.refreshToken;
     const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "xr-token": `${refreshToken}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
    };
    return this.httpClient.get<CommonResponse>(
      this.baseUrl + "HaloSOS/GetTrackingByIMEI/" + id
    );
  }
}
